import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Container from '@/components/Layout/Container'
import TextAndImage from '@/components/TextAndImage/'
import Wysiwyg from '@/components/Wysiwyg'
import AwardHeader from '@/components/AwardHeader'
import RoundedLink from '@/components/RoundedLink'
import ImageLarge from '@/components/Image/ImageLarge'
import Image from '@/components/Image/'
import ContainerSidebar from '@/components/Layout/ContainerSidebar'
import Seo from '@/components/Seo'
import Pagination from '@/components/Pagination'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { seotype } from '@/types.d'
interface SingleAwardCategoryData {
  data: {
    form: any
    category: {
      name: string
      description: string
      image: any
      language: {
        slug: string
      }
      uri: string
      seo: seotype
      translations: []
    }
    awards: any
    categories: {
      nodes: []
    }
    mainMenu: any
    wp: any
  }
}

const SingleAwardCategory = ({ data }: SingleAwardCategoryData) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(category?.language.slug)
  }, [])

  const { category } = data
  const { awards } = data
  const { form } = data
  const { categories } = data
  const latestAward = awards?.nodes[0]
  const secondLatestAward = awards?.nodes[1]
  const { mainMenu } = data
  const { wp } = data
  const langData = { languages: wp.languages, currentLang: category.language.slug, translations: category.translations }

  return (
    <Layout featuredImage={category.image} lang={langData} nav={mainMenu} form={form}>
      {category.seo && (
        <Seo
          title={category?.seo?.title}
          description={category?.seo?.metaDesc}
          keywords={category?.seo?.metaKeyword}
          pathname={category?.uri}
          lang={category?.language?.slug}
          twitterTitle={category?.seo?.twitterTitle}
          twitterDescription={category?.seo?.twitterDescription}
          opengraphTitle={category?.seo?.opengraphTitle}
          opengraphDescription={category?.seo?.opengraphDescription}
          opengraphImage={category?.seo?.opengraphImage?.localFile?.url}
          twitterImage={category?.seo?.twitterImage?.localFile?.url}
        />
      )}
      <AwardHeader heading={category.name} aside={categories?.nodes}>
        <Wysiwyg className="max-w-screen-md w-full my-10">{category.description}</Wysiwyg>
      </AwardHeader>
      {latestAward && (
        <Container className="my-12 md:my-20">
          {latestAward.gwAwardRecipient.length > 1 || latestAward.gwAwardForceMultipleRecipient ? (
            <>
              <ImageLarge image={latestAward.gwAwardImage} />
              <div className="flex items-center justify-center mt-28">
                <div className="max-w-screen-md w-full">
                  <div className="wysiwyg">
                    {latestAward.gwAwardShortSummary && (
                      <p className="font-bold text-2xl">{latestAward.gwAwardShortSummary}</p>
                    )}
                    {latestAward.gwAwardLongSummary && <p>{latestAward.gwAwardLongSummary}</p>}
                  </div>
                  {latestAward.uri && (
                    <RoundedLink
                      className="border-black text-black hover:text-white focus:text-white hover:bg-black focus:bg-black"
                      to={latestAward.uri}
                    >
                      <Trans>Lue lisää</Trans>
                    </RoundedLink>
                  )}
                </div>
              </div>
            </>
          ) : (
            <TextAndImage
              link={latestAward.uri}
              linkText={t('Lue lisää')}
              contentUpper={latestAward.gwAwardShortSummary}
              img={latestAward.gwAwardImage}
              imgSide="left"
              imgBg={'bg-latte'}
              className="mt-8 md:mt-20 mb-12 md:mb-32"
            >
              <p>{latestAward.gwAwardLongSummary}</p>
            </TextAndImage>
          )}
        </Container>
      )}
      <ContainerSidebar
        className="my-20"
        sidebar={
          secondLatestAward && (
            <>
              <Image alt="kuva" image={secondLatestAward.gwAwardImage} />{' '}
              <p className="mt-4 leading-normal text-sm">{secondLatestAward.gwAwardShortSummary}</p>
            </>
          )
        }
      >
        <h2 className="text-3xl">
          <Trans>Palkittuja</Trans>
        </h2>
        <Pagination posts={awards.nodes} />
      </ContainerSidebar>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AwardCategoryById(
    $id: String!
    $slug: [String]
    $postlang: [String]
    $menu: [WpMenuLocationEnum]
    $translang: String
    $formNum: Int
  ) {
    category: wpGwAwardsCustomTaxonomySingle(id: { eq: $id }) {
      id
      name
      description
      image: gwAwardCatImage
      language {
        code
        slug
      }
      seo {
        metaDesc
        metaKeywords
        title
        twitterTitle
        twitterDescription
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            url
          }
        }
        twitterImage {
          localFile {
            url
          }
        }
      }
      translations {
        uri
        language {
          slug
        }
      }
    }
    categories: allWpGwAwardsCustomTaxonomySingle(
      filter: { language: { slug: { in: $postlang } } }
      sort: { fields: gwAwardCatOrder, order: ASC }
    ) {
      nodes {
        uri
        id
        name
      }
    }
    awards: allWpGwAward(
      sort: { fields: gwAwardDate, order: DESC }
      filter: { terms: { nodes: { elemMatch: { slug: { in: $slug } } } }, language: { slug: { in: $postlang } } }
    ) {
      ...allAwards
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    wp {
      ...allLang
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: $formNum }) {
      ...GravityFormFields
    }
  }
`

export default SingleAwardCategory
